<template>
    <div class="page-order-list">
        <div class="toolbars mrgb5">
            <div class="fl"></div>
            <div class="fr">
                <el-date-picker class="c-datetime-range mrgr5 mrgb5" v-model="selectedTime" type="datetimerange" size="medium"
                    :picker-options="pickerOptions" :default-time="['00:00:00', '23:59:59']" @change="_search"
                    range-separator="至"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    align="right">
                </el-date-picker>
                <el-select class="mrgr5 mrgb5" style="width: 160px;" v-model="listQuery.Status" multiple collapse-tags clearable
                    @change="_search()" placeholder="订单状态" size="medium">
                    <el-option v-for="item in statusOptions" :key="item.value" :label="item.name" :value="item.value"></el-option>
                </el-select>
                <el-select class="mrgr5 mrgb5" style="width: 120px;" v-model="listQuery.PaymentType" placeholder="支付方式" clearable
                    @change="_search()" size="medium">
                    <el-option v-for="item in paymentTypeOptions"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"></el-option>
                </el-select>
                <el-select class="mrgr5 mrgb5" style="width: 120px;" v-model="listQuery.IsReturned" placeholder="归还状态" clearable
                    @change="_search()" size="medium">
                    <el-option :value="true" label="已归还"></el-option>
                    <el-option :value="false" label="未归还"></el-option>
                </el-select>
                <el-select class="mrgr5 mrgb5" style="width: 150px;" v-model="listQuery.MerchantId" filterable remote clearable reserve-keyword placeholder="选择商家(输入搜索)"
                    :remote-method="renderMerchantOptions" @change="_search()" size="medium">
                    <el-option v-for="item in merchantItems" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
                <el-select class="mrgr5 mrgb5" style="width: 150px;" v-model="listQuery.AgentId" filterable remote clearable reserve-keyword placeholder="代理(输入搜索)"
                    :remote-method="renderAgentOptions" @change="_search()" size="medium">
                    <el-option v-for="item in agentItems" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
                <el-select class="mrgr5 mrgb5" style="width: 150px;" v-model="listQuery.MemberId" filterable remote clearable reserve-keyword placeholder="会员(输入搜索)"
                    :remote-method="renderMemberOptions" @change="_search()" size="medium">
                    <el-option v-for="item in memberItems" :key="item.id" :label="`${item.name}【${item.id}】`" :value="item.id"></el-option>
                </el-select>
                <el-input class="c-el-input mrgr5 mrgb5" placeholder="订单号/设备号" clearable v-model="listQuery.Keywords" size="medium"></el-input>
                <el-button type="primary" plain size="small" @click="_search()"><i class="fa fa-search"></i> 查询</el-button>
            </div>
            <div class="clearb"></div>
        </div>
        <div>
            <el-table :data="tableData" border fit highlight-current-row row-key="id" @sort-change="sortChange">
                <el-table-column prop="id" label="订单号"></el-table-column>
                <el-table-column label="商家/位置" width="200px">
                    <template slot-scope="scope">
                        <div>{{ scope.row.serviceMerchant }}</div>
                        <div>({{ scope.row.serviceLocation }})</div>
                    </template>
                </el-table-column>
                <el-table-column label="设备" width="200px">
                    <template slot-scope="scope">
                        {{ scope.row.deviceTypeName }}<br />
                        {{ scope.row.deviceId }}
                        <div>
                            输液宝: {{ scope.row.terminalId }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="会员" width="150px">
                    <template slot-scope="scope">
                        <div class="user-item" @click="queryUser(scope.row.member)">
                            <div class="avatar" :style="`background-image:url(${ scope.row.member.head })`"></div>
                            <div class="info-wrap">
                                <div class="user-name">{{ scope.row.member.name }}</div>
                                <div class="sub-info">
                                    <div class="user-id">ID:{{ scope.row.member.id }}</div>
                                    <el-tag type="danger" size="mini" v-if="scope.row.isStaffOrder">员工</el-tag>
                                </div>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="服务时间" width="250px">
                    <template slot-scope="scope">
                        <template v-if="scope.row.status==2">时长:{{scope.row.currentServiceMinutes || 0}}分钟</template>
                        <template v-else>时长:{{scope.row.serviceMinutes || 0}}分钟</template>
                        <div v-if="scope.row.startServiceTime">开始时间:{{scope.row.startServiceTime}}</div>
                        <div v-if="scope.row.endServiceTime">结束时间:{{scope.row.endServiceTime}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="rentAmount" label="租金" sortable="custom" width="100px">
                    <template slot-scope="scope">
                        <div>
                            <template v-if="scope.row.status==2">预计{{scope.row.currentRent || 0}}元</template>
                            <template v-else>{{scope.row.rentAmount || 0}}元</template>
                        </div>
                        <el-tag type="warning" size="mini" v-if="scope.row.paymentTypeDesc">{{scope.row.paymentTypeDesc}}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="status" label="订单状态" sortable="custom" width="110px">
                    <template slot-scope="scope">
                        <el-tag size="mini" type="success">{{scope.row.statusDesc}}</el-tag><br />
                        <el-tag v-if="scope.row.status>=4" size="mini">{{scope.row.isReturned?'已归还':scope.row.flag?scope.row.flagDesc:'未归还'}}</el-tag>
                        <el-tag v-if="!scope.row.isReturned && scope.row.status==8&&!scope.row.flag" size="mini">超时订单</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="creationTime" label="创建时间" sortable="custom" width="110px">
                    <template slot-scope="scope">
                        {{ scope.row.creationTime }}
                    </template>
                </el-table-column>
                <el-table-column label="备注/原因" prop="remark"></el-table-column>
                <el-table-column label="操作" width="100px" fixed="right">
                    <template slot-scope="scope">
                        <el-dropdown trigger="click">
                            <el-button type="primary" plain size="mini">更多<i class="el-icon-arrow-down el-icon--right"></i></el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item @click.native="skipToRoute(`/order/details?id=${scope.row.id}`)">查看详细</el-dropdown-item>
                                <el-dropdown-item @click.native="completeOrder(scope.row)" 
                                    :disabled="scope.row.status != 2 && scope.row.status != 4">完成订单</el-dropdown-item>
                                <el-dropdown-item @click.native="orderRefund(scope.row.id)"
                                    :disabled="scope.row.status!=8 || scope.row.rentAmount <= 0">退款</el-dropdown-item>
                                <el-dropdown-item @click.native="orderUp(scope.row.id, scope.row.paymentStatus)"
                                    v-if="scope.row.paymentTypeDesc == '支付宝支付' && scope.row.paymentStatus != 1">上报</el-dropdown-item>
                                <el-dropdown-item @click.native="cancelOrder(scope.row.id)"
                                    :disabled="scope.row.status < 1">取消</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination-container" v-if="isPagination">
                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" 
                    :current-page="listQuery.Page" :page-sizes="appPageSizes" :page-size="listQuery.Size" 
                    layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>
        </div>

        <complete-order-dialog ref="completeOrderDialog" @confirm="getDataList"></complete-order-dialog>
        <order-refund-dialog ref="orderRefundDialog" @cofirm="getDataList"></order-refund-dialog>
        <order-up ref="orderUpDialog" @cofirm="getDataList"></order-up>
        <!--会员详情-->
        <user-detail :ref="refUserDetail"></user-detail>
    </div>
</template>

<script>
import enumConfigs from "@/mixins/enumConfigs"
import * as funOrder from "@/api/order/index"
import * as funMerchant from "@/api/merchant/index"
import * as funAgent from "@/api/agent/index"
import * as funMember from "@/api/member/index"
import CompleteOrderDialog from "./components/CompleteOrderDialog"
import OrderRefundDialog from "./components/OrderRefundDialog"
import OrderUp from './components/OrderUp'
import UserDetail from "@/views/admin/member/components/detail"
import moment from 'moment'
export default {
    name: "pageOrderList",
    mixins: [enumConfigs],
    components: { CompleteOrderDialog, OrderRefundDialog, OrderUp, UserDetail },
    data() {
        const that = this
        return {
            refUserDetail: "refOrderListToMemberDetail",
            tableData: [],
            total: null,
            isPagination: false,
            listQuery: {
                Page: 1,
                Size: window.$common.appPageSize,
                Keywords: "",
                StartDate: null,
                EndDate: null,
                Status: [],
                MerchantId: "",
                PaymentType: "",
                AgentId: "",
                IsStaffOrder:null,
                IsReturned: '',
                MemberId: null,
                SortField: null,
                SortDirection: 0
            },
            selectedTime: [],
            paymentTypeOptions: [
                { name: "微信", value: 8 },
                { name: "支付宝", value: 16 }
            ],
            merchantItems: [],
            agentItems: [],
            memberItems: [],
            pickerOptions: {
                shortcuts: [
                    {
                        text: "今天",
                        onClick: (picker) => {
                            let timeArr = that.setPickerTime(picker, 1, "days")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一周",
                        onClick: (picker) => {
                            let timeArr = that.setPickerTime(picker, 1, "weeks")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一月",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 1, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近三月",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 3, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近半年",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 6, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一年",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 1, "years")
                            that.setQueryTime(timeArr)
                        }
                    }
                ]
            }
        }
    },
    computed: {
        statusOptions() {
            return  this.arrLeaseOrderStatus;
        }
    },
    activated() {
        if (this.listQuery.MemberId != this.$route.query.memberId) {
            this.listQuery.MemberId = this.$route.query.memberId;
            this.renderMemberOptions(this.listQuery.MemberId);
        }
        this.getDataList()
    },
    mounted() {
        if (this.$route.query.memberId) {
            this.listQuery.MemberId = this.$route.query.memberId
            this.renderMemberOptions(this.listQuery.MemberId)
        } else if (this.$route.query.paymentType){
            this.listQuery.PaymentType = Number(this.$route.query.paymentType)
        }
        if (this.$route.query.status || this.$route.query.status === '0'){
            this.listQuery.Status.push(Number(this.$route.query.status))
        }
        // 根据参数知道是否需要根据时间搜索 从统计点击过来
        if (this.$route.query.time=="0"){
            this.getTime(0)
        } else if (this.$route.query.time === "1"){
            this.getTime(1)
        } else if (this.$route.query.time === "2"){
            this.getTime(7)
        } else if (this.$route.query.time === "3"){
            this.getTime(30)
        }
        if (this.$route.query.isStaffOrder == '1'){
            this.listQuery.IsStaffOrder = true
        }
        if (this.$route.query.keywords){
            this.listQuery.Keywords=this.$route.query.keywords
        }

        this.renderMerchantOptions("")
        this.renderAgentOptions("")
        this.getDataList()
    },
    methods: {
        setQueryTime(timeArr) {
            this.listQuery.StartDate = timeArr[0] || ""
            this.listQuery.EndDate = timeArr[1] || ""
        },
        handleSizeChange(val) {
            this.listQuery.Size = val
            this.getDataList()
        },
        handleCurrentChange(val) {
            this.listQuery.Page = val
            this.getDataList()
        },
        // 处理传过来的时间数字
        getTime(days){
            if (days == 1) {
                this.listQuery.StartDate = moment().subtract(1, "days").format("YYYY-MM-DD 00:00:00")
                this.listQuery.EndDate = moment().subtract(1, "days").format("YYYY-MM-DD 23:59:59")
                // this.listQuery.StartDate = new Date(new Date() - days * 24 * 3600 * 1000).Format("yyyy-MM-dd 00:00:00")
                // this.listQuery.EndDate = new Date(new Date() - days * 24 * 3600 * 1000).Format("yyyy-MM-dd 23:59:59")
                this.selectedTime = [this.listQuery.StartDate, this.listQuery.EndDate]
            } else {
                this.listQuery.StartDate = moment().subtract(1, "days").format("YYYY-MM-DD 00:00:00")
                this.listQuery.EndDate = moment().format("YYYY-MM-DD 23:59:59")
                this.selectedTime = [this.listQuery.StartDate, this.listQuery.EndDate]
            } 
        },
        setSelectedTime() {
            let psTime = ""
            let peTime = ""
            if (this.selectedTime && this.selectedTime[0] && moment(this.selectedTime[0]).isValid()) {
                psTime = moment(this.selectedTime[0]).format("YYYY-MM-DD HH:mm:ss")
            }
            if (this.selectedTime && this.selectedTime[1] && moment(this.selectedTime[1]).isValid()) {
                peTime = moment(this.selectedTime[1]).format("YYYY-MM-DD HH:mm:ss")
            }
            this.listQuery.StartDate = psTime
            this.listQuery.EndDate = peTime
            this.selectedTime = [this.listQuery.StartDate, this.listQuery.EndDate]
        },
        getDataList() {
            this.setSelectedTime()
            window.$common.fullLoading()
            funOrder.QueryPagedOrders(this.listQuery).then(response => {
                window.$common.closeFullLoading()
                this.total = response.totalCount
                this.totalAmount = response.totalAmount
                this.isPagination = response.totalCount > 0 || false
                if (response && response.items && response.items.length > 0) {
                    this.tableData = response.items
                } else {
                    this.tableData = []
                }
            })
        },
        async renderMerchantOptions(query) {
            await funMerchant.GetAllMerchants({ keywords: query }).then(res => {
                this.merchantItems = res
            })
        },
        async renderAgentOptions(query) {
            await funAgent.GetAgentAll({ keywords: query }).then(res => {
                this.agentItems = res
            })
        },
        async renderMemberOptions(query) {
            await funMember.QueryMemberList({ 
                page: 1,
                size: 10,
                keywords: query
            }).then(res => {
                this.memberItems = res.items
            })
        },
        async cancelOrder(id) {
            this.prompt("请输入取消原因:", "提示", {
                closeOnClickModal: false,
                closeOnPressEscape: false,
                inputValue: "",
                beforeClose: (action, instance, done) => {
                    if (action == "confirm") {
                        if (instance.inputValue) {
                            done()
                        } else {
                            this.warningMsg("请输入取消原因!")
                        }
                    } else {
                        done()
                    }
                }
            }).then(res => {
                let commitData = {
                    id: id,
                    reason: res.value
                }
                funOrder.CancelOrder(commitData).then(res => {
                    this.successMsg("取消成功")
                    this.getDataList()
                })
            })
        },
        // 查看会员信息
        queryUser(row) {
            this.$refs[this.refUserDetail].show(row)
        },
        // 完成订单
        completeOrder(row) {
            this.$refs.completeOrderDialog.show(row)
        },
        // 退款
        orderRefund(id) {
            this.$refs.orderRefundDialog.show(id)
        },
        // 上报
        orderUp(orderNo, paymentStatus) {
            this.$refs.orderUpDialog.show(orderNo, paymentStatus)
        },
        async sortChange(e) {
            console.log(e)
            if (e.order) {
                this.listQuery.SortField = e.prop;
                if (e.order == "ascending") {
                    this.listQuery.SortDirection = 0;
                } else {
                    this.listQuery.SortDirection = 1;
                }
            } else {
                this.listQuery.SortDirection = null;
                this.listQuery.SortField = null;
            }
            this._search();
        },
        _search() {
            this.listQuery.Page = 1
            this.getDataList()
        }
    }
}
</script>

<style lang="scss" scoped>
    .page-order-list {
        .user-item {
        display: flex;
        align-items: center;
        cursor: pointer;
        .avatar {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-position: center;
            background-size: cover;
            margin-right: 5px;
        }
        .user-name {
            font-weight: bold;
        }
        .info-wrap {
            flex: 1;
            overflow: hidden;
        }
        .sub-info {
            display: flex;
            align-items: center;
            .user-id {
            margin-right: 4px;
            }
        }
        }
    }
</style>