<template>
    <el-dialog :title="`订单退款,订单号:${formData.id}`" :visible.sync="dialogFormVisible">
        <el-form :model="formData" ref="auditForm" :rules="rules">
            <el-form-item label="退款金额" label-width="120px" prop="amount">
                <el-input v-model="formData.amount"></el-input>
                <div class="input-tips">退款后各分成代理需要扣除指定比例的余额</div>
            </el-form-item>
            <el-form-item label="退款原因" label-width="120px" prop="reason">
                <el-input v-model="formData.reason" type="textarea"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="submitAudit">确 定</el-button>
        </div>
    </el-dialog>
</template>
<script>
import * as funOrder from "@/api/order/index"
export default {
    data() {
        return {
            dialogFormVisible: false,
            formData: {
                amount: "",
                reason: "",
                id: ""
            },
            rules: {
                amount: [{ required: true, message: "选择输入退款金额", trigger: "blur" }],
                reason: [{ required: true, message: "请输入原因", trigger: "blur" }]
            }
        }
    },
    methods: {
        show(id) {
            this.dialogFormVisible = true;
            this.formData = {
                amount: "",
                reason: "",
                id
            };
        },
        submitAudit() {
            this.$refs["auditForm"].validate(async (valid) => {
                if (valid) {
                    window.$common.fullLoading()
                    funOrder.OrderRefund(this.formData).then(res => {
                        window.$common.closeFullLoading()
                        this.dialogFormVisible = false
                        this.successMsg("退款成功")
                        this.$emit("confirm")
                    })
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>

</style>