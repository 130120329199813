<template>
    <el-dialog :title="`手动完成订单，ID:${formData.id}`" :visible.sync="dialogFormVisible" v-if="orderItem">
        <el-form :model="formData" ref="completeForm" :rules="rules">
            <template v-if="orderItem.status == 4">
                <el-form-item label="是否已扣款" label-width="120px" prop="deductioned">
                    <el-radio-group v-model="formData.deductioned">
                        <el-radio :label="true">是</el-radio>
                        <el-radio :label="false">否</el-radio>
                    </el-radio-group>
                    <div class="input-tips">如果已扣款，只是更新订单状态以便结算订单</div>
                </el-form-item>
                <template v-if="formData.deductioned">
                    <el-form-item label="交易号" label-width="120px" prop="tradeNo">
                        <el-input v-model="formData.tradeNo"></el-input>
                        <div class="input-tips">如果已扣款成功，填入交易号以备注订单</div>
                    </el-form-item>
                    <el-form-item label="退款号" label-width="120px" prop="refundNo" v-if="orderItem.orderType == 1">
                        <el-input v-model="formData.refundNo"></el-input>
                        <div class="input-tips">预付订单的退款号</div>
                    </el-form-item>
                </template>
            </template>
            <!--  -->
            <template v-else-if="orderItem.status == 2">
                <el-form-item label="归还时间" label-width="120px" prop="returnTime">
                    <el-date-picker v-model="formData.returnTime" type="datetime" placeholder="选择日期时间"
                        value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
                    <div class="input-tips">如果不填则为当前时间</div>
                </el-form-item>
            </template>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="submitDeliver">确 定</el-button>
        </div>
    </el-dialog>
</template>
<script>
import * as funOrder from "@/api/order/index"
export default {
    name: "compCompleteOrderDialog",
    data() {
        return {
            dialogFormVisible: false,
            formData: {
                deductioned: false,
                tradeNo: "",
                refundNo: "",
                returnTime: "",
                id: ""
            },
            orderItem: {},
            rules: {
                tradeNo: [{ required: true, message: "请输入交易号", trigger: "blur" }],
                refundNo: [{ required: true, message: "请输入退款号", trigger: "blur" }],
                returnTime: [{ required: true, message: "请输入归还时间", trigger: "blur" }]
            }
        }
    },
    methods: {
        show(orderItem) {
            this.dialogFormVisible = true;
            this.orderItem = orderItem;
            this.formData = {
                deductioned: false,
                tradeNo: "",
                refundNo: "",
                returnTime: "",
                id: orderItem.id
            }
        },
        submitDeliver() {
            this.$refs["completeForm"].validate(async (valid) => {
                if (valid) {
                    window.$common.fullLoading()
                    funOrder.CompleteOrder(this.formData).then(res => {
                        window.$common.closeFullLoading()
                        this.dialogFormVisible = false
                        this.successMsg("完成成功")
                        this.$emit("confirm")
                    })
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>

</style>