<template>
    <el-dialog :title="`上报,订单号:${formData.orderNo}`" :visible.sync="dialogFormVisible">
      <el-form :model="formData" ref="auditForm" :rules="rules">
        <el-form-item label="状态" label-width="120px" prop="alipayOrderBizInfo">
          <el-select v-model="formData.alipayOrderBizInfo" placeholder="请选择">
              <el-option
              v-for="item in orderStatus"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled">
              </el-option>
          </el-select>
          <!-- <div class="input-tips">上报后15天内，如用户用其他方式支付，可以修改违约状态</div> -->
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitAudit">确 定</el-button>
      </div>
    </el-dialog>
  </template>
  <script>
  import * as funOrder from "@/api/order/index"
  export default {
    data() {
        return {
            dialogFormVisible: false,
            formData: {
                alipayOrderBizInfo: "0",
                orderNo: ""
            },
            orderStatus: [
                { label: '守约', value:"0", disabled: false },
                { label: '违约', value:"-2", disabled: false },
                { label: '关闭', value:"-1", disabled: false }
            ],
            rules: {
                alipayOrderBizInfo: [{ required: true, message: "请选择", trigger: "blur" }],
            }
        }
    },
    methods: {
        show(orderNo, paymentStatus) {
            let paymentStatus1 = ''
            if (paymentStatus==2) { //已支付
                this.orderStatus[1].disabled = true
                paymentStatus1='0'
            } else if (paymentStatus == 0){ //未支付
                this.orderStatus[0].disabled = true
                paymentStatus1='-2'
            }
            this.dialogFormVisible = true;
            this.formData = {
                alipayOrderBizInfo: paymentStatus1,
                orderNo
            };
        },
        submitAudit() {
            this.$refs["auditForm"].validate(async (valid) => {
                if (valid) {
                    window.$common.fullLoading()
                    funOrder.CreditReport({
                        orderNo: this.formData.orderNo,
                        alipayOrderBizInfo: this.formData.alipayOrderBizInfo
                    }).then(res => {
                        window.$common.closeFullLoading()
                        this.dialogFormVisible = false;
                        this.successMsg("上报成功");
                        this.$emit("confirm");
                    })
                }
            });
        }
    }
  }
  </script>
  <style lang="scss" scoped></style>